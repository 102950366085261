/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { withCache } from '@ngneat/cashew';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, EndpointV2, GraphqlService, HttpService, replaceTemplateParams } from '@ws1c/intelligence-common';
import {
  Automation,
  AutomationActionFieldLookupResponse,
  AutomationActionLookupRequest,
  AutomationLogSearchResponse,
  AutomationPreviewResponse,
  AutomationQuota,
  AutomationsByActionIdSearchRequest,
  AutomationSearchRequest,
  AutomationSearchResponse,
  AutomationTemplate,
  AutomationTemplateSearchResponse,
  AutomationTestActionRequest,
  AutomationTestActionResponse,
  AutomationType,
  COLUMN_NAMES,
  DeemIncident,
  PreviewReportContentRequest,
  SearchTerm,
} from '@ws1c/intelligence-models';
import {
  COPY_AUTOMATION,
  CREATE_AUTOMATION,
  DELETE_AUTOMATION,
  DISABLE_AUTOMATION,
  ENABLE_AUTOMATION,
  GET_AUTOMATION_BY_ID,
  SEARCH_AUTOMATION,
  UPDATE_AUTOMATION,
} from './automation.graphql';

/**
 * AutomationService
 * @export
 * @class AutomationService
 */
@Injectable({
  providedIn: 'root',
})
export class AutomationService {
  /**
   * Creates an instance of AutomationService.
   * @param {HttpService} httpService
   * @param {GraphqlService} graphqlService
   * @memberof AutomationService
   */
  constructor(
    private httpService: HttpService,
    private graphqlService: GraphqlService,
  ) {}

  /**
   * getAutomationLogs
   *
   * @param {PreviewReportContentRequest} automationLogRequest
   * @returns {Observable<AutomationLogSearchResponse>}
   * @memberof AutomationService
   */
  public getAutomationLogs(automationLogRequest: PreviewReportContentRequest): Observable<AutomationLogSearchResponse> {
    return this.httpService.post(EndpointV2.CONTENT_SEARCH, automationLogRequest).pipe(
      map((response: any) => deserialize(AutomationLogSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAutomations
   *
   * @param {AutomationSearchRequest} searchRequest
   * @param {AutomationType} automationType
   * @param {string} sourceObjectId
   * @returns {Observable<AutomationSearchResponse>}
   * @memberof AutomationService
   */
  public getAutomations(
    searchRequest: AutomationSearchRequest,
    automationType?: AutomationType,
    sourceObjectId?: string,
  ): Observable<AutomationSearchResponse> {
    const newSearchRequest = new AutomationSearchRequest({
      ...searchRequest,
    });
    const searchTerm = newSearchRequest.searchTerm;
    delete newSearchRequest.searchTerm;
    if (searchTerm && !newSearchRequest.searchTerms) {
      newSearchRequest.searchTerms = [
        new SearchTerm({
          fields: [COLUMN_NAMES.byName.name, COLUMN_NAMES.byName.description],
          value: searchTerm.value,
        }),
      ];
    }

    if (sourceObjectId) {
      newSearchRequest.searchTerms = [
        ...(newSearchRequest.searchTerms ?? []),
        new SearchTerm({
          fields: [COLUMN_NAMES.byName.source_object_id],
          value: sourceObjectId,
        }),
      ];
    }

    if (automationType) {
      newSearchRequest.searchTerms = [
        ...(newSearchRequest.searchTerms ?? []),
        new SearchTerm({
          fields: [COLUMN_NAMES.byName.categories],
          value: automationType,
        }),
      ];
    }

    return this.graphqlService
      .query(SEARCH_AUTOMATION, {
        searchInput: newSearchRequest,
      })
      .pipe(
        map((response: GenericObject) => {
          // GraphQL search calls return little different response structure, thus massaging data to same format
          response = {
            ...response?.searchAutomationSummaries?.paged_response,
            results: response?.searchAutomationSummaries?.automation_summaries,
          };
          return deserialize(AutomationSearchResponse, response);
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * getAutomationsByActionIds
   *
   * @param {AutomationsByActionIdSearchRequest} request
   * @returns {Observable<AutomationSearchResponse>}
   * @memberof AutomationService
   */
  public getAutomationsByActionIds(request: AutomationsByActionIdSearchRequest): Observable<AutomationSearchResponse> {
    return this.httpService.post(Endpoint.AUTOMATIONS_BY_ACTION_IDS, request).pipe(
      map((response: any) => deserialize(AutomationSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * createAutomationV2
   *
   * @param {Automation} automation
   * @returns {Observable<Automation>}
   * @memberof AutomationService
   */
  public createAutomation(automation: Automation): Observable<Automation> {
    return this.graphqlService
      .mutate(CREATE_AUTOMATION, {
        automationInput: automation,
      })
      .pipe(
        map((response: GenericObject) => {
          response = {
            ...response?.createAutomation,
            actions: response?.createAutomation?.workflow?.actions,
          };
          return deserialize(Automation, response);
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * updateAutomation
   *
   * @param {Automation} automation
   * @returns {Observable<Automation>}
   * @memberof AutomationService
   */
  public updateAutomation(automation: Automation): Observable<Automation> {
    return this.graphqlService
      .mutate(UPDATE_AUTOMATION, {
        automationInput: automation,
      })
      .pipe(
        map((_response: GenericObject) => {
          const { id, name } = _response.updateAutomation;
          return new Automation({
            id,
            name,
          });
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * executeAutomation
   *
   * @param {string} automationId
   * @returns {Observable<boolean>}
   * @memberof AutomationService
   */
  public executeAutomation(automationId: string): Observable<boolean> {
    return this.httpService.post(Endpoint.AUTOMATION_EXECUTE(automationId), {}).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteAutomation
   *
   * @param {string} ruleId
   * @returns {Observable<Automation>}
   * @memberof AutomationService
   */
  public deleteAutomation(ruleId: string): Observable<Automation> {
    return this.graphqlService
      .mutate(DELETE_AUTOMATION, {
        ruleId,
      })
      .pipe(
        map((response: any) => response),
        catchError(requestErrorHandler),
      );
  }

  /**
   * cloneAutomation
   *
   * @param {Automation} automation
   * @returns {Observable<Automation>}
   * @memberof AutomationService
   */
  public cloneAutomation(automation: Automation): Observable<Automation> {
    return this.graphqlService
      .mutate(COPY_AUTOMATION, {
        ruleId: automation.id,
        copyName: automation.name,
      })
      .pipe(
        map((response: any) => deserialize(Automation, response.copyAutomation)),
        catchError(requestErrorHandler),
      );
  }

  /**
   * renameAutomation
   *
   * @param {Automation} automation
   * @returns {Observable<Automation>}
   * @memberof AutomationService
   */
  public renameAutomation(automation: Automation): Observable<Automation> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        name: automation.name,
      },
    });

    const requestOptions = {
      params,
    };

    return this.httpService.put(Endpoint.AUTOMATION_RENAME(automation.id), {}, requestOptions).pipe(
      map((response: any) => deserialize(Automation, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * testAutomationConnectorAction
   * @param {AutomationTestActionRequest} payload
   * @returns {Observable<AutomationTestActionResponse>}
   * @memberof AutomationService
   */
  public testAutomationConnectorAction(payload: AutomationTestActionRequest): Observable<AutomationTestActionResponse> {
    return this.httpService.post(Endpoint.AUTOMATION_ACTION_TEST, payload).pipe(
      map((response: GenericObject) => {
        const dataWithRawResponse = deserialize(AutomationTestActionResponse, response.data);
        dataWithRawResponse.rawResponse = response.data;
        return dataWithRawResponse;
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * setAutomationActive
   *
   * @param {Automation} automation
   * @param {boolean} active
   * @returns {Observable<boolean>}
   * @memberof AutomationService
   */
  public setAutomationActive(automation: Automation, active: boolean): Observable<boolean> {
    return this.httpService.post(Endpoint.AUTOMATION_ACTIVE_TOGGLE(automation.id, active), {}).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAutomationTemplatesV3
   *
   * @returns {Observable<AutomationTemplate[]>}
   * @memberof AutomationService
   */
  public getAutomationTemplatesV3(): Observable<AutomationTemplate[]> {
    return this.httpService
      .get(EndpointV2.AUTOMATION_TEMPLATES_V3, {
        context: withCache(),
      })
      .pipe(
        map((response: any) => {
          return deserialize(AutomationTemplateSearchResponse, response).data;
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * getAutomationTemplateDetailsV3
   *
   * @param {AutomationTemplate} automationTemplate
   * @returns {Observable<Automation>}
   * @memberof AutomationService
   */
  public getAutomationTemplateDetailsV3(automationTemplate: AutomationTemplate): Observable<Automation> {
    const { name, integration, targetEntity, params }: AutomationTemplate = automationTemplate;
    return this.httpService
      .get(EndpointV2.AUTOMATION_TEMPLATE_DETAILS_V3(integration, targetEntity, name), {
        context: withCache(),
      })
      .pipe(
        map((response: any) => {
          let automation = response.data;
          if (params) {
            automation = replaceTemplateParams(automation, params);
          }
          return deserialize(Automation, automation);
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * getAutomationPreviewResults
   *
   * @returns {*}  {Observable<AutomationPreviewResponse>}
   * @memberof AutomationService
   */
  public getAutomationPreviewResults(): Observable<AutomationPreviewResponse> {
    return this.httpService.get(Endpoint.AUTOMATION_PREVIEW).pipe(
      map((response: any) => deserialize(AutomationPreviewResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAutomationActionLookup
   *
   * @param {AutomationActionLookupRequest} fieldLookupRequest
   * @returns {Observable<AutomationActionFieldLookupResponse>}
   * @memberof AutomationService
   */
  public getAutomationActionLookup(fieldLookupRequest: AutomationActionLookupRequest): Observable<AutomationActionFieldLookupResponse> {
    return this.httpService.post(Endpoint.AUTOMATION_ACTIONS_LOOKUP, fieldLookupRequest).pipe(
      map((fieldLookupResponse: AutomationActionFieldLookupResponse) => {
        return deserialize(AutomationActionFieldLookupResponse, fieldLookupResponse);
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAutomationQuotas
   *
   * Retrieves daily email capacity settings
   * @returns {Observable<AutomationQuota[]>} - Returns observable of AutomationQuota[]
   * @memberof AutomationService
   */
  public getAutomationQuotas(): Observable<AutomationQuota[]> {
    return this.httpService.get(Endpoint.AUTOMATION_QUOTAS).pipe(
      map((response: any) => response.data.map((quotaData: AutomationQuota) => deserialize(AutomationQuota, quotaData))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAutomation
   *
   * @param {string} automationId
   * @returns {Observable<Automation>}
   * @memberof AutomationService
   */
  public getAutomation(automationId: string): Observable<Automation> {
    return this.graphqlService.query(GET_AUTOMATION_BY_ID, { id: automationId }).pipe(
      map((response: any) => {
        return deserialize(Automation, response.automation);
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getIncident
   *
   * @param {string} id
   * @returns {Observable<DeemIncident>}
   * @memberof AutomationService
   */
  public getIncident(id: string): Observable<DeemIncident> {
    return this.httpService.get(Endpoint.INCIDENTS_ID(id)).pipe(
      map((response: GenericObject) => deserialize(DeemIncident, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * disableAutomation
   *
   * @param {Automation} automation
   * @returns {Observable<Automation>}
   * @memberof AutomationService
   */
  public disableAutomation(automation: Automation): Observable<Automation> {
    return this.graphqlService
      .mutate(DISABLE_AUTOMATION, {
        ruleId: automation.id,
      })
      .pipe(
        map(() => {
          return new Automation({
            ...automation,
            active: false,
          });
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * enableAutomation
   *
   * @param {Automation} automation
   * @returns {Observable<Automation>}
   * @memberof AutomationService
   */
  public enableAutomation(automation: Automation): Observable<Automation> {
    return this.graphqlService
      .mutate(ENABLE_AUTOMATION, {
        workflowInput: {
          name: automation.name,
          description: automation.description,
          active: automation.active,
          actions: automation.actions,
        },
        ruleId: automation.id,
      })
      .pipe(
        map(() => {
          return new Automation();
        }),
        catchError(requestErrorHandler),
      );
  }
}
