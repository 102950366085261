/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, EndpointV2, GenericResponse, HttpService } from '@ws1c/intelligence-common';
import {
  AggregationAttributesResponse,
  Category,
  CategoryColumns,
  CategoryList,
  Column,
  CustomAttributeIdentifiersSearchRequest,
  CustomAttributeIdentifiersSearchResponse,
  PrecomputedAggregation,
  SuggestionCriteria,
  SuggestionSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * IntegrationMetaService
 * @export
 * @class IntegrationMetaService
 */
@Injectable({
  providedIn: 'root',
})
export class IntegrationMetaService {
  /**
   * constructor
   * @param {HttpService} httpService
   * @memberof IntegrationMetaService
   */
  constructor(private httpService: HttpService) {}

  /**
   * getAttributesForEntitiesByIntegration
   * @param {GenericObject} entitiesByIntegration
   * @returns {Observable<CategoryColumns>}
   * @memberof IntegrationMetaService
   */
  public getAttributesForEntitiesByIntegration(entitiesByIntegration: GenericObject): Observable<CategoryColumns> {
    return this.httpService.post(Endpoint.BULK_ATTRIBUTES, entitiesByIntegration).pipe(
      map((response: any) => deserialize(CategoryColumns, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getIntegrationMetadata
   * @returns {Observable<Category[]>}
   * @memberof IntegrationMetaService
   */
  public getIntegrationMetadata(): Observable<Category[]> {
    return this.httpService.get(Endpoint.INTEGRATION_META_ROOT).pipe(
      map((response: any) => deserialize(CategoryList, response).data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getFilterSuggestionsV2
   * @param {SuggestionCriteria} suggestionCriteria
   * @returns {Observable<SuggestionSearchResponse>}
   * @memberof IntegrationMetaService
   */
  public getFilterSuggestionsV2(suggestionCriteria: SuggestionCriteria): Observable<SuggestionSearchResponse> {
    return this.httpService.post(EndpointV2.INTEGRATION_META_SUGGESTIONS, suggestionCriteria).pipe(
      map((response: GenericResponse) => deserialize(SuggestionSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAttributesForIntegrationAndEntityV2
   * @param {Category} category
   * @param {boolean} isCrossCategory
   * @returns {Observable<Column[]>}
   * @memberof IntegrationMetaService
   */
  public getAttributesForIntegrationAndEntityV2(category: Category, isCrossCategory: boolean): Observable<Column[]> {
    return this.httpService
      .get(EndpointV2.INTEGRATION_ENTITY_ATTRIBUTES(category.integration.name, category.entity.name, !isCrossCategory))
      .pipe(
        map((response: any) => response.data.map((column) => deserialize(Column, column))),
        catchError(requestErrorHandler),
      );
  }

  /**
   * getPrecomputedAggregations
   * @param {string} integration
   * @param {string} entity
   * @returns {PrecomputedAggregation[]}
   * @memberof IntegrationMetaService
   */
  public getPrecomputedAggregations(integration: string, entity: string): Observable<PrecomputedAggregation[]> {
    return this.httpService.get(Endpoint.AGGREGATION_INTEGRATION_ENTITY_TRENDS(integration, entity)).pipe(
      map((response: GenericObject) => response.data.map((trend: GenericObject) => deserialize(PrecomputedAggregation, trend))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAggregationAttributes
   * @param {string} id
   * @returns {Observable<AggregationAttributesResponse>}
   * @memberof IntegrationMetaService
   */
  public getAggregationAttributes(id: string): Observable<AggregationAttributesResponse> {
    return this.httpService.get(Endpoint.AGGREGATION_TREND_ID(id)).pipe(
      map((response: GenericObject) => deserialize(AggregationAttributesResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getCustomAttributeIdentifiers
   * @param {CustomAttributeIdentifiersSearchRequest} request
   * @returns {Observable<CustomAttributeIdentifiersSearchResponse>}
   * @memberof IntegrationMetaService
   */
  public getCustomAttributeIdentifiers(
    request: CustomAttributeIdentifiersSearchRequest,
  ): Observable<CustomAttributeIdentifiersSearchResponse> {
    return this.httpService.post(EndpointV2.STRUCTMAP_IDENTIFIERS, request).pipe(
      map((response: GenericObject) => deserialize(CustomAttributeIdentifiersSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getCustomAttributesIdentifierAttributes
   * @param {Map<string, Set<string>>} customAttributeIdentifiersByAttributeName
   * @returns {Observable<Column[]>}
   * @memberof IntegrationMetaService
   */
  public getCustomAttributesIdentifierAttributes(
    customAttributeIdentifiersByAttributeName: Map<string, Set<string>>,
  ): Observable<Map<string, Column[]>> {
    const postData = {
      base_attribute_to_identifiers_map: {},
    };
    customAttributeIdentifiersByAttributeName.forEach((value: Set<string>, key: string) => {
      postData.base_attribute_to_identifiers_map[key] = Array.from(value);
    });
    return this.httpService.post(EndpointV2.STRUCTMAP_IDENTIFIER_ATTRIBUTES, postData).pipe(
      map((response: GenericObject) => {
        const customAttributeIdentifierAttributesByKey = new Map<string, Column[]>();
        response.data?.forEach((customAttributeObject: GenericObject) => {
          const customAttribute = customAttributeObject.struct_map_attribute;
          customAttributeObject?.struct_element_attributes_details?.forEach((customAttributeIdentifierAttributesObject: GenericObject) => {
            const identifier = customAttributeIdentifierAttributesObject.identifier;
            const key: string = `${customAttribute} - ${identifier}`;
            const customAttributeIdentifierAttributes: Column[] = customAttributeIdentifierAttributesObject?.attributes.map(
              (column: GenericObject) => {
                return deserialize(Column, column);
              },
            );
            customAttributeIdentifierAttributesByKey.set(key, customAttributeIdentifierAttributes);
          });
        });
        return customAttributeIdentifierAttributesByKey;
      }),
      catchError(requestErrorHandler),
    );
  }
}
